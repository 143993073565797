@use '../../../assets/style/colors' as color;

/* IMAGE: RIGHT SIDE */
.bg-image{
    // background-image: url("../../../assets/img/hb/image-login.jpg");
    background-color: #cccccc;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 50vw ;
}

.bg-image-text{
    text-align: center;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 100%;
}

.transbox {
    /* margin: 30px; */
    background-image: linear-gradient(0deg, rgba(57,93,132,.5), rgba(2, 10, 20, 0.5));
    /* background: rgba(57,93,132,.5); */
    /* color: white; */
    padding: 4%;
}

.default-fontColor{
    color: color.$color-HB-blue-principal;
}


/* REGISTER: LEFT SIDE */

.red-placeholder-select{
    color: red !important;
}

.red-placeholder::placeholder{
    color: red !important;
}

.red-input-border{
    border: 2px solid red !important;
}

.div-register{
    width: 50vw;
}
.img-logo{
    width: 10vw;
}


hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid color.$color-HB-blue-principal;
    margin: 1em 0;
    padding: 0;
    min-width: 10.5vw;
}

.h6{
    max-height: 3.5vh;
    margin-top: -5px;
}


.input-register{
    border-radius: 20px;
    width: 50%;
    border: 1px solid color.$color-HB-blue-light;
    min-height: 4.5vh;
    color: color.$color-HB-blue-strong;
    padding-left: 2vh;
    padding-right: 2vh;
}

.input-register-credential{
    border-radius: 20px;
    width: 100%;
    border: 1px solid color.$color-HB-blue-light;
    min-height: 4.5vh;
    color: color.$color-HB-blue-strong;
    padding-left: 2vh;
    padding-right: 2vh;
}

.option-register{
    border-radius: 20px;
    width: 50%;
    border: 1px solid color.$color-HB-blue-strong;
    min-height: 4.5vh;
    color: color.$color-HB-blue-strong;
    padding-left: 1.5vh;
    padding-right: 1vh;
    font-weight: bold;
    opacity: .7;
    color: color.$color-HB-blue-strong;
    background-color: white;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
}


.input-register-credencial{
    border-radius: 20px;
    width: 100%;
    border: 1px solid color.$color-HB-blue-light;
    min-height: 4.5vh;
    color: color.$color-HB-blue-strong;
    padding-left: 2vh;
    padding-right: 2vh;
}

.shadow-blue{
    -webkit-box-shadow: 0px 10px 11px -8px rgba(0,73,144,0.2) !important;
    box-shadow: 0px 10px 11px -8px rgba(0,73,144,0.2) !important;
}

.input-register::placeholder {
    font-weight: bold;
    opacity: .7;
    color: color.$color-HB-blue-strong;
}

.input-register-credential::placeholder {
    font-weight: bold;
    opacity: .7;
    color: color.$color-HB-blue-strong;
}


.option-register:focus{
    outline: none !important;
    border-color: color.$color-HB-blue-strong;
    color: color.$color-HB-blue-light;  
    padding-left: 1.5vh;
}

.option-register-credential:focus{
    outline: none !important;
    border-color: color.$color-HB-blue-strong;
    color: color.$color-HB-blue-light;  
    padding-left: 1.5vh;
}



.input-register:focus{
    outline: none !important;
    border-color: color.$color-HB-blue-strong;
    color: color.$color-HB-blue-light;  
    padding-left: 2vh;
}

.dot {
    margin: 10px;
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid color.$color-HB-blue-principal;
}

.br{
    margin: 1vh;
}

.button-div{
	border: 2px solid color.$color-HB-blue-light;
    width: 50%;
    border-radius: 20px;
    min-height: 4.5vh;
    display: flex;
    align-items: center;
}

.button-div i{
	padding-left: 2vh;
}

.button-div button{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    width: 99%;
    color: color.$color-HB-blue-strong;
    font-weight: 500;
}

.button-div:hover{
    font-weight: 500;
    background: #cee3f7;
}

.link-noStyle{
    /* background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: color.$color-HB-blue-strong;; */
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    color: color.$color-HB-blue-strong;
}

.link-noStyle:hover {
    text-decoration: none;
    color: color.$color-HB-blue-strong;
    font-weight: 500;
}

.button-loader{
    background: color.$color-HB-blue-strong;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: white;
    width: 25%;
    border-radius: 20px;
    border: 1px solid color.$color-HB-blue-light;
    min-height: 4.5vh;
    font-weight: bold;
}

.button-continuar{
    background: color.$color-HB-blue-strong;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: white;
    width: 25%;
    border-radius: 20px;
    border: 2px solid color.$color-HB-blue-light;
    min-height: 4.5vh;
    font-weight: bold;
}

.button-continuar:hover{
    background: white;
    color: color.$color-HB-blue-strong;
}

.register-box{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.register-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.register-row-2{
    display: flex;
    justify-content: space-between;
    width: 100%;
}


/* TOGGLE */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider-si:before{
      content: "SI";
  }

  .slider-no:before{
        content: "NO";
    }
  
  .slider:before {
    display: flex;
    align-items: center;
    justify-content: center; 
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    color: color.$color-HB-blue-strong;
    font-weight: 500;
  }
  
  input:checked + .slider {
    background-color: color.$color-HB-blue-strong;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .tester{
    content: "si" !important
  }
  
  /* Rounded sliders */
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.toggle-box{
    width: 50%;
}


@media only screen and (max-width: 1350px){

    .h6{
        max-height: 6vh;
        margin-top: 8px;
    }

    .toggle-box{
        width: 100%;
    }

    .input-register{
        border-radius: 20px;
        width: 100% !important;
        border: 1px solid color.$color-HB-blue-light;
        min-height: 4.5vh;
        color: color.$color-HB-blue-strong;
        padding-left: 2vh;
        padding-right: 2vh;
    }

    .input-register-credencial{
        border-radius: 20px;
        width: 100% !important;
        border: 2px solid red;
        min-height: 4.5vh;
        border: 2px solid color.$color-HB-blue-light;
        padding-left: 2vh;
        padding-right: 2vh;
    }

    .register-row{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    .option-register{
        width: 100% !important;
    }
}


@media only screen and (max-width: 1200px){

    .button-div{
        min-height: 6vh;
    }

    .input-register{
        min-height: 6vh;
        width: 80%;
    }

    .input-register-credencial{
        min-height: 6vh;
    }

    .option-register{
        min-height: 6vh;
        width: 100%;
    }

    hr {
        min-width: 17vw;
    }

    .register-box{
        width: 79%;
    }

    .button-div{
        width: 80%;
    }

    .img-logo{
        width: 15vw;
    }

    .button-continuar{
        width: 50%;
        min-height: 6vh;
    }

    .button-loader{
        width: 50%;
        min-height: 6vh;
    }

}


@media only screen and (max-width: 800px){

    .option-default-size{
        font-size: small !important;
    }

    .button-div{
        min-height: 6vh;
    }

    .input-register{
        min-height: 6vh;
    }

    .input-register-credencial{
        min-height: 6vh;
    }


    .div-register{
        width: 100vw;
    }

    .bg-image{
        display: none;
    }

    .input-register{
        width: 80%;
    }

    .register-box{
        width: "80%";
    }

    hr {
        min-width: 34.5vw;
    }

    .register-box{
        width: 79%;
    }

    .button-div{
        width: 80%;
    }

    .img-logo{
        width: 30vw;
    }

    .button-continuar{
        width: 50%;
        min-height: 6vh;
    }

    .button-loader{
        width: 50%;
        min-height: 6vh;
    }    

    .img-logo
    {
        margin-top: 20px;
    }

}

.privacidad
{
   /* align-self: flex-start; */
    /* margin-left: 10vh; */

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
}